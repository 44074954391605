import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Select from 'common/components/Select';
import Container from 'common/components/UI/Container';
import SocialProfile from '../SocialProfile';
import FooterWrapper, { List, ListItem } from './footer.style';
import AppImage from 'common/assets/image/ride/footerapp.svg';
import PlaystoreImage from 'common/assets/image/ride/footerplay.svg';

const currentYear = new Date().getFullYear();
const copy = `© ${currentYear} Loobi. Todos los derechos reservados.`;

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      rideJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            url
          }
        }
        Language_NAMES {
          label
          value
        }
        SOCIAL_PROFILES {
          icon
          url
        }
      }
    }
  `);

  return (
    <FooterWrapper id="footerSection">
      <Container noGutter mobileGutter width="1200px">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Heading content="Idioma" {...titleStyle} />
            <Select
              options={Data.rideJson.Language_NAMES}
              placeholder="Español"
              className="Language_search_select"
              aria-label="language switcher"
            />
            <Heading
              content="Descarga La App"
              {...titleStyle}
              className="appDownload"
            />
            <Box className="imageWrapper">
              <a href="https://apps.apple.com/es/app/loobi/id6448645674">
                <Image src={AppImage} alt="Apple Store badge to download Loobi for iOS" />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.hextron.loobi">
                <Image src={PlaystoreImage} alt="Google Play badge to download Loobi for Android" />
              </a>
            </Box>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.rideJson.menuWidget.map((widget) => (
              <Box className="col" {...col} key={widget.id}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item) => (
                    <ListItem key={`list__item-${item.id}`}>
                      <a href={item.url} className="ListItem">
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row copyRight" {...row}>
          <Text
            content={copy}
            className="copyRightText"
          />
          <SocialProfile
            className="footer_social"
            items={Data.rideJson.SOCIAL_PROFILES}
            iconSize={18}
          />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '50%', '50%', '50%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '50%', '50%', '50%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 2, 1 / 2, 1 / 2],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px'],
  },
  // widget title default style
  titleStyle: {
    color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Poppins',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
};

export default Footer;
